@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);

body {
    margin: 0;
    font-family: 'papyrus';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  li {
    list-style: none;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
/* BluuNext */
@font-face {
  font-family: 'Bluu Next';
  src: local('Bluu Next'), url(/static/media/BluuNext-Bold.62617b94.otf);
}

/* Office Code PRO */
@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-Regular.568927fb.woff) format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-RegularItalic.829ee485.woff) format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-Medium.fa33df79.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-MediumItalic.e0d7ac01.woff) format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-Light.8a5df412.woff) format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-LightItalic.027a6316.woff) format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-Bold.ed2eb92b.woff) format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'OfficeCodePro';
  src: local('OfficeCodePro'), url(/static/media/OfficeCodePro-BoldItalic.193d9358.woff) format('woff');
  font-weight: bold;
  font-style: italic;
}

