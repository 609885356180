
body {
    margin: 0;
    font-family: 'papyrus';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  li {
    list-style: none;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }